import React, { useEffect, useState, useRef } from 'react'
// import { graphql } from 'gatsby'
import {
  ChakraProvider,
  extendTheme,
  Heading,
  Box,
  Flex,
  SimpleGrid,
  FormControl,
  RadioGroup,
  Radio,
  Input,
  Stack,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react';
// import PhoneNumberInput from '../utils/usePhoneNumberFormat'
import axios from "axios"
import Layout from '../components/Layout'
import pageTheme from '../gatsby-plugin-chakra-ui/theme'
import SEO from '../components/SEO'
import '../assets/slick/slick.scss'
import useGetPlansByID from '../utils/useGetPlansByID'
import useGetAddonsByID from '../utils/useGetAddonsByID'
import themeVars from '../gatsby-plugin-chakra-ui/theme-vars'
import MTALogo from '../assets/svg/mta_logo.inline.svg'
import BroadbandLabel from '../components/Blocks/Alopex/BroadbandLabel/BroadbandLabel'

const theme = extendTheme({ ...pageTheme })

const PageShare = ({ location }) => {
  const [labelId, setLabelId] = useState(null)
  const refInitialFocus = useRef(null)
  const [isCustomer, setIsCustomer] = useState(null)
  const [isInvalid, setIsInvalid] = useState(false)
  const [serverState, setServerState] = useState({
    submitting: false,
    status: null
  });
  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg }
    });
    if (ok) {
      form.reset();
    }
  };
  const handleOnSubmit = e => {
    e.preventDefault();
    const form = e.target;
    setServerState({ submitting: true });
    let phone = form.querySelector('#phone-number').value
    const phoneRegex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/g
    if (phone.match(phoneRegex) !== null) {
      setIsInvalid(false)
      axios({
        method: "post",
        url: "https://getform.io/f/akkgvela",
        data: new FormData(form)
      })
      .then(r => {
        handleServerResponse(true, "Thanks!", form);
      })
      .catch(r => {
        handleServerResponse(false, r.response.data.error, form);
      });
    } else {
      setIsInvalid(true)
    }
  }

  console.log('Location: ', location)
  // Get order details from URL
  let planID = ''
  let addonIDs = ''
  let address = ''
  let mdue = ''
  let fname = ''
  let lname = ''
  let email = ''
  let phone = ''
  if (location.search !== '') {
    const params = new URLSearchParams(location.search)
    // Plan
    planID = params.get("planID")
    console.log('planID: ', planID)
    
    // Add-ons
    addonIDs = params.get("addonIDs")
    console.log('addonIDs: ', addonIDs)

    // Service Address
    address = params.get('address')

    // Total Due Monthly
    mdue = params.get('mdue')

    // Customer info
    fname = params.get('fname')
    lname = params.get('lname')
    email = params.get('email')
    phone = params.get('phone')

  }
  const plan = useGetPlansByID(planID)
  console.log('Plan: ', plan)
  
  const addons = useGetAddonsByID(addonIDs.split(','))
  console.log('Addons: ', addons)

  useEffect(() => {
    if (typeof plan !== 'null') {
      setLabelId(plan[0].labelId)
    }
  }, [plan])
  
 
  return (
    <ChakraProvider theme={theme}>
      <Layout
        heroContent={{
          heroSlides: [{
              heroSlideIllustrationSelect: 'home',
              heroSlideContentH1: '<strong>Your Plan</strong>, Your Terms',
              heroSlideContentH4: 'Endless browsing, streaming, and gaming. No gimmicks. No throttling. No hidden fees.',
              heroSlideLink: null,
              secondaryButton: null,
              teritaryButton: null,
          }]
        }}
      >
          <SEO title="Share Internet Plan" noIndex />
          <Box mb="150px" className="container" maxWidth="1440px">
            <Box
              className="mtaOrderLogo"
              display="none"
              maxW="120px"
              mb="20px"
              sx={{
                svg: {
                  fill: themeVars.colors.blue
                }
              }}
            >
              <MTALogo />
            </Box>
            <Flex justify="space-between" align="center">
              <Heading as="h2">Internet Order Summary</Heading>
              <Box className="printButton">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" style={{maxWidth: '30px', width: '100%', color: themeVars.colors.blue, cursor: 'pointer'}} onClick={() => window.print()}>
                  <rect x="2.34" y="13.09" width="45.33" height="23.82" rx="3.76" ry="3.76" fill="currentColor"/>
                  <circle cx="39.36" cy="17.88" r="1.6" fill="#fff"/>
                  <circle cx="44.06" cy="17.88" r="1.6" fill="#fff"/>
                  <path d="M39.36 13.09V1.54H16.74l-6.11 6.11v5.44M10.64 29.93h28.73v18.51H10.64z" fill="#fff" stroke="currentColor" strokeLinejoin="round" strokeWidth="2"/>
                  <path stroke="currentColor" fill="currentColor" strokeLinejoin="round" strokeWidth="2" d="M16.75 1.54v6.11h-6.11"/>
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15.41 43.75h19.18M15.41 39.05h16.1M15.41 34.33h7.96"/>
                </svg>
              </Box>
            </Flex>

            <Heading as="h4">Customer Information</Heading>
            <Box mb="20px">
              <Flex gridGap="10px" mb="10px" flexWrap="wrap">
                <Flex justify="space-between" p="12px 15px" rounded="10px" border={`1px solid ${themeVars.colors.blue}`} flex="1 1">
                  <Heading as="h6" mb="0!important"><strong>First</strong></Heading>
                  <Heading as="h6" mb="0!important">{fname}</Heading>
                </Flex>
                <Flex justify="space-between" p="12px 15px" rounded="10px" border={`1px solid ${themeVars.colors.blue}`} flex="1 1">
                  <Heading as="h6" mb="0!important"><strong>Last</strong></Heading>
                  <Heading as="h6" mb="0!important">{lname}</Heading>
                </Flex>
              </Flex>
              <Flex gridGap="10px" mb="10px" flexWrap="wrap">
                <Flex justify="space-between" p="12px 15px" rounded="10px" border={`1px solid ${themeVars.colors.blue}`} flex="1 1">
                  <Heading as="h6" mb="0!important"><strong>Email</strong></Heading>
                  <Heading as="h6" mb="0!important">{email}</Heading>
                </Flex>
                <Flex justify="space-between" p="12px 15px" rounded="10px" border={`1px solid ${themeVars.colors.blue}`} flex="1 1">
                  <Heading as="h6" mb="0!important"><strong>Phone</strong></Heading>
                  <Heading as="h6" mb="0!important">{phone}</Heading>
                </Flex>
              </Flex>
              <Flex justify="space-between" p="12px 15px" rounded="10px" border={`1px solid ${themeVars.colors.blue}`}>
                <Heading as="h6" mb="0!important"><strong>Service Address</strong></Heading>
                <Heading as="h6" mb="0!important">{address}</Heading>
              </Flex>
            </Box>
            
            <Heading as="h4">Plan</Heading>
            <Box mb="20px">
              {plan.length ? (
                <Flex justify="space-between" p="12px 15px" rounded="10px" border={`1px solid ${themeVars.colors.blue}`}>
                  <Heading as="h5" mb="0!important"><strong>{plan[0]?.planOptions[0]?.ptplanName}</strong></Heading>
                  <Heading as="h6" mb="0!important">${plan[0]?.planOptions[0]?.ptplanPrice}</Heading>
                </Flex>
              ) : (
                <Heading as="h6">No plan selected.</Heading>
              )}
            </Box>
            
            <Heading as="h4">Add-ons</Heading>
            <Box mb="20px">
              {addons.length ? (
                <>
                  {addons.map((addon, i) => {
                    return (
                    <Flex justify="space-between" p="12px 15px" rounded="10px" border={`1px solid ${themeVars.colors.blue}`} mb="10px">
                      <Heading as="h6" mb="0!important" dangerouslySetInnerHTML={{ __html: addon?.addonTitle}}/>
                      <Heading as="h6" mb="0!important">${addon?.price}</Heading>
                    </Flex>
                    )
                  })}
                </>
              ) : (
                <Heading as="h6">No addons selected.</Heading>
              )}
            </Box>
            
            <Box as="hr" mt="40px" pb="40px" />

            <Box mb="50px">
              <Flex justify="space-between" p="12px 15px" rounded="10px" border={`1px solid ${themeVars.colors.blue}`}>
                <Heading as="h6" mb="0!important"><strong>Due Monthly</strong></Heading>
                <Heading as="h6" mb="0!important">${mdue}</Heading>
              </Flex>
            </Box>

            <Box display="grid" gridTemplateColumns={['1fr', '1fr 1fr']} gap="50px">
              <Box
                border={`1px solid ${themeVars.colors.blue}`}
                p="20px"
                rounded="10px"
                sx={{
                  '.bcl_widget-container': {
                    border: 'none',
                    margin: 0,
                    padding: 0,
                    color: themeVars.colors.blue,
                    '.bcl_title': {
                      '.bcl_h1': {
                        fontSize: '1.333333333rem'
                      },
                      '&::after': {
                        background: themeVars.colors.blue,
                        borderRadius: '999px',
                        height: '0.5em'
                      }
                    },
                    '.bcl_provider, .support': {
                      '&::after': {
                        background: themeVars.colors.blue,
                        borderRadius: '999px',
                        height: '0.3em'
                      }
                    },
                    '.monthly, .clear-space, .discounts': {
                      '&::after': {
                        background: themeVars.colors.blue,
                        borderRadius: '999px',
                        height: '0.2em'
                      }
                    }
                  }
                }}
              >
                {labelId !== null && (
                  <BroadbandLabel attributes={{ data: { label_id: labelId } }} />
                )}
              </Box>
              <Box className="orderForm">
                <Heading as="h2">Ready to order?</Heading>
                <form method="post" onSubmit={handleOnSubmit}>
                  <Box mb="20px">
                    <RadioGroup my={['10px']} name="Existing MTA Customer?" value={isCustomer}>
                      <Box as="p" mb={0}>
                        Are you an existing MTA customer?
                      </Box>
                      <Stack direction="row">
                        <Radio value={true} colorScheme="mint" isRequired onChange={() => setIsCustomer(true)}>Yes</Radio>
                        <Radio value={false} colorScheme="purple" isRequired onChange={() => setIsCustomer(false)}>No</Radio>
                      </Stack>
                    </RadioGroup>
                  </Box>

                  <SimpleGrid columns={[1, 2]} spacing="20px" mb="20px">

                    <FormControl id="first-name" isRequired>
                      <Input name="First Name" placeholder="First Name" ref={refInitialFocus} defaultValue={fname} />
                    </FormControl>

                    <FormControl id="last-name" isRequired>
                      {/* <FormLabel>Last name</FormLabel> */}
                      <Input name="Last Name" placeholder="Last Name" defaultValue={lname} />
                    </FormControl>

                    <FormControl id="email" isRequired>
                      <Input name="Email" type="email" placeholder="Email" defaultValue={email} />
                    </FormControl>

                    <FormControl id="phone-number" isRequired>
                      <Input name="phone" type="tel" placeholder="Phone" defaultValue={phone} />
                    </FormControl>

                  </SimpleGrid>

                  <FormControl id="address" isRequired>
                    <Input name="address" placeholder="Address" defaultValue={address} mb="20px" />
                  </FormControl>

                  {/* SUBMIT / SUCCESS / ERROR */}
                  {serverState.status ? (
                    <Box>
                      {!serverState.status.ok ? (
                        <Alert status="error" mt="20px" borderRadius="10px">
                          <AlertIcon />
                          <AlertTitle mr={2}>Oops! There was an error.</AlertTitle>
                          <AlertDescription>{serverState.status.msg} Please <a href="tel:9077453211">call customer support</a> to complete your order.</AlertDescription>
                        </Alert>
                      ) : (
                        <Alert status="success" mt="20px" colorScheme="mint" variant="solid" borderRadius="10px">
                          <AlertIcon />
                          <AlertTitle mr={2}>Your request has been received!</AlertTitle>
                          <AlertDescription>Thank you for submitting the form. We appreciate your interest and will be in touch with you soon.</AlertDescription>
                        </Alert>
                      )}
                    </Box>
                  ) : (
                    <SimpleGrid columns={[1, 2]}>
                      <Box></Box>
                      <Box>
                        <Input
                          background="auto"
                          className="themeGradient"
                          type="submit"
                          value="Submit"
                          color={themeVars.colors.white}
                          cursor="pointer"
                          disabled={serverState.submitting}
                        />
                      </Box>
                    </SimpleGrid>
                  )}

                </form>
              </Box>
            </Box>
          </Box>
          <style>{`
            @media print {
              .headerContainer, .footer, .printButton svg, .orderForm {display: none!important;}
              .mtaOrderLogo {display: block!important;}
            }
          `}</style>
      </Layout>
    </ChakraProvider>
  )
}

// export const query = graphql`
//   query internetPage {
//     plans: allWpPlan(sort: {fields: plan___ptplanOrder}) {
//       nodes {
//         databaseId
//         plan {
//           ptplanOptions {
//             ptplanName
//             ptplanSlug
//             ptplanPrice
//           }
//         }
//       }
//     }

//     addons: allWpPlanAddon(sort: {fields: addon___ptaddonOrder}) {
//       nodes {
//         databaseId
//         title
//         addon {
//           ptaddonPrice
//         }
//       }
//     }
//   }
// `

export default PageShare